import { EmployeeData, TimesheetHeader } from '@ta-global-kiosk/domain';
import { BASE_API_URL } from '../config/ConfigConstants';

export const getEmployeeData = async (accessToken: string, badgeId?: string, employeeId?: string): Promise<EmployeeData> => {
  const url = new URL(`${BASE_API_URL}/employeeData`);
  if (employeeId) {
    url.searchParams.append('employeeId', employeeId);
  }
  if (badgeId) {
    url.searchParams.append('badgeId', badgeId);
  }
  const headers: any = { authorization: `Bearer ${accessToken}` };
  const response = await fetch(url.toString(), {
    headers,
  });
  const jsonResult = await response.json();

  return jsonResult;
};

export const getTimesheetHeaders = async (accessToken: string, badgeId?: string): Promise<TimesheetHeader[]> => {
  const url = `${BASE_API_URL}/timesheets`;
  const headers: any = { authorization: `Bearer ${accessToken}` };
  if (badgeId) {
    headers.cardid = badgeId;
  }
  const response = await fetch(url, {
    headers,
  });
  const jsonResult = await response.json();

  return jsonResult;
};
