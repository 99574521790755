// import { TableInstance, useSortBy, useTable } from 'react-table';
import { NewTable } from './NewTable';
import { useState } from 'react';
import { useReactTable, getCoreRowModel, getSortedRowModel, getFilteredRowModel, SortingState, ColumnFiltersState } from '@tanstack/react-table';

interface TableViewerProps {
  columns: any[];
  data: any[];
  isLoading: boolean;
}

export function TableViewer({ columns, data, isLoading }: TableViewerProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <>
      <NewTable instance={tableInstance} loading={isLoading}></NewTable>
    </>
  );
}
