import { CircularProgress, Stack, Table } from '@lego/klik-ui';
import { useTranslation } from 'react-i18next';
import { formatLocalFromUTC, parseDate } from '../utils/datetime';
import { EmployeeAbsenceViewerProps } from '../utils/types';

export const EmployeeAbsenceViewer = ({ absences, isLoading }: EmployeeAbsenceViewerProps) => {
  const { t } = useTranslation();
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeader>{t('Absence.start')}</Table.ColumnHeader>
          <Table.ColumnHeader>{t('Absence.end')}</Table.ColumnHeader>
          <Table.ColumnHeader>{t('Absence.hours')}</Table.ColumnHeader>
          <Table.ColumnHeader>{t('Absence.name')}</Table.ColumnHeader>
        </Table.Row>
      </Table.Head>
      <Table.Body fontSize={'sm'}>
        {isLoading && (
          <Table.Row>
            <Table.Cell colSpan={4} alignItems='center'>
              <Stack alignItems='center'>
                <CircularProgress m={10} isIndeterminate={true} value={3} />
              </Stack>
            </Table.Cell>
          </Table.Row>
        )}
        {!isLoading &&
          absences.length > 0 &&
          absences
            .sort((a, b) => a.startDate.localeCompare(b.startDate))
            .map((a) => {
              return (
                <Table.Row key={a.type + a.startDate}>
                  <Table.Cell>{formatLocalFromUTC(parseDate(a.startDate))}</Table.Cell>
                  <Table.Cell>{formatLocalFromUTC(parseDate(a.endDate))}</Table.Cell>
                  <Table.Cell>{a.hours}</Table.Cell>
                  <Table.Cell>{a.name}</Table.Cell>
                </Table.Row>
              );
            })}
      </Table.Body>
    </Table>
  );
};
