import { Outlet } from 'react-router-dom';
import { NavbarContainer } from '../components/NavbarContainer';
import { Container, Stack } from '@lego/klik-ui';
import { OutletProps } from '../utils/types';

export const OutletLayout = (props: OutletProps) => {
  return (
    <>
      <NavbarContainer navItems={props.navItems} />

      <Stack align='center' direction='column'>
        <Container maxW='container.xl'>
          <Outlet />
        </Container>
      </Stack>
    </>
  );
};
