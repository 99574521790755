import { Absence, Quota } from '@ta-global-kiosk/domain';
import { PathRouteProps } from 'react-router-dom';

export interface KioskRouteProps extends PathRouteProps {
  title: string;
  dropdownItems?: KioskRouteProps[];
}

export interface NavbarProps {
  // navTitle: string;
  navItems: KioskRouteProps[];
}

export interface ListElement {
  key: string;
  value: string;
}

export interface ListRenderProps {
  listElements: ListElement[];
}

export interface ProtectedRouteProps {
  isAllowed: boolean;
  children?: JSX.Element;
}

export interface OutletProps {
  navItems: KioskRouteProps[];
}

export interface RouterProps {
  location: Location;
  manager: boolean;
  name: string;
}

export enum Location {
  DK = 'dk',
  MX = 'mx',
  HU = 'hu',
  CN = 'cn',
  CZ = 'cz',
  OTHER = 'other',
}

export interface IUser {
  username: string;
  displayName?: string;
  isManager: boolean;
  isKioskServiceAccount: boolean;
  canImpersonate: boolean;
  employeeId: string;
  location: Location;
  roles?: string[];
}

export interface EmployeeQuotaViewerProps {
  quotas: Quota[];
  isLoading: boolean;
}

export interface EmployeeAbsenceViewerProps {
  absences: Absence[];
  isLoading: boolean;
}
