import { TableViewer } from '../../components/TableViewer';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Button, ButtonGroup, HorizontalStack, Spacer, Stack, Text } from '@lego/klik-ui';
import { DateBold, LayoutListBold } from '@lego/klik-ui/icons';
import { TimeEvent } from '@ta-global-kiosk/domain';
import { useEffect, useMemo, useState } from 'react';
import { useAuthData } from '../../contexts/AuthProvider';
import { PeriodeCarousel } from '../../components/PeriodeCarousel';
import { getDataByManager } from '../../utils/data/time';
import { useTranslation } from 'react-i18next';
import { generateWeekPeriods } from '../../utils/datetime';
import { TimeEventWeekView } from '../../components/TimeEventWeekView';

const columnHelper = createColumnHelper<TimeEvent>();

export const EmployeeTimeEvents = () => {
  const periodes = useMemo(() => {
    return generateWeekPeriods(60).reverse();
  }, []);
  const [periode, setPeriode] = useState(periodes[periodes.length - 1].id);
  const [view, setView] = useState<'table' | 'calendar'>('table');
  const { instance } = useAuthData();
  const [data, setData] = useState<TimeEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return getColumns(t);
  }, [t]);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      setIsLoading(true);
      try {
        const [start, end] = periode.split('#');
        const data = await getDataByManager(
          instance,
          'timeEvents',
          new Date(start),
          new Date(end),
          abortController,
        );
        setData(data);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    return () => {
      abortController.abort();
    };
  }, [instance, periode]);

  return (
    <Box w={'100%'} m={4}>
      <Text as='h1' textStyle='h1' mt={5}>
        Time events for your direct reports for
      </Text>
      <HorizontalStack>
        <Spacer />
        <ButtonGroup size='sm' spacing='0' variant='outline'>
          <Button
            borderRightRadius={0}
            onClick={() => setView('calendar')}
            backgroundColor={view === 'calendar' ? 'dark-blue.500' : 'white'}
            color={view === 'calendar' ? 'white' : 'dark-blue.500'}
            _hover={{ backgroundColor: 'dark-blue.500', color: 'white' }}
            leftIcon={<DateBold />}></Button>
          <Button
            onClick={() => setView('table')}
            borderLeftRadius={0}
            backgroundColor={view === 'table' ? 'dark-blue.500' : 'white'}
            color={view === 'table' ? 'white' : 'dark-blue.500'}
            _hover={{ backgroundColor: 'dark-blue.500', color: 'white' }}
            leftIcon={<LayoutListBold />}></Button>
        </ButtonGroup>
      </HorizontalStack>

      <PeriodeCarousel
        periodes={periodes}
        initialSelection={periodes.length - 1}
        onSelectionChange={(p) => setPeriode(p.id)}
      />
      {view === 'table' && (
        <TableViewer data={data} columns={columns} isLoading={isLoading}></TableViewer>
      )}
      {view === 'calendar' && (
        <TimeEventWeekView data={data} periode={periode} isLoading={isLoading} />
      )}
    </Box>
  );
};

const getColumns = (t: any) => {
  return [
    columnHelper.accessor('employeeName', {
      header: () => t('TimeEvent.employee'),
      minSize: 200,
      cell: ({ row }) => {
        const { employeeName, employeeNumber } = row.original;
        return (
          <Stack spacing='0'>
            <Text color='slate.600'>{employeeNumber.replace(/^0+/, '')}</Text>
            <Text>{employeeName}</Text>
          </Stack>
        );
      },
    }),
    columnHelper.accessor('sapId', {
      header: () => t('TimeEvent.sap_id'),
      maxSize: 130,
      cell: (info) => info.getValue().replace(/^0+/, ''),
    }),
    columnHelper.accessor('type', {
      header: () => t('TimeEvent.type'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('reason', {
      header: () => t('TimeEvent.reason'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('logicalDate', {
      header: () => t('TimeEvent.date'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('logicalTime', {
      header: () => t('TimeEvent.time'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('terminalId', {
      header: () => t('TimeEvent.terminalId'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
  ];
};

// const getPeriodes = (): Periode[] => {
//   const weeks = generateWeeks(60);
//   return weeks.map((week) => {
//     const [start, end] = week.split('#');
//     return {
//       id: week,
//       display: `${start} - ${end}`,
//     };
//   });
// };
