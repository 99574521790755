import {
  AuthenticationResult,
  EventMessage,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { createContext, FC, ReactNode, useContext, useState, useEffect } from 'react';
import { ErrorPage } from '../pages/routes/ErrorPage';
import { IUser, Location } from '../utils/types';
import { changeLanguage } from 'i18next';

import { graphConfig, loginRequest, msalConfig } from '../utils/auth/authConfig';
import apm from '../utils/monitoring/apm';

const msalInstance = new PublicClientApplication(msalConfig);

export type TAuthContext = {
  instance: IPublicClientApplication;
  user: IUser | null;
  acquireTokenSilent: (request: SilentRequest) => Promise<AuthenticationResult | null>;
};

export const AuthContext = createContext<TAuthContext>({
  instance: msalInstance,
  user: null,
  acquireTokenSilent: async () => {
    return null;
  },
});

interface IAuthProvider {
  children: ReactNode;
}

const callMsGraph = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const getLocation = (companyCode: string) => {
  if (companyCode === '1001') {
    return Location.DK;
  }
  if (companyCode === '1002' || companyCode === '1056' || companyCode === '1004') {
    return Location.MX;
  }
  if (companyCode === '1003') {
    return Location.HU;
  }
  if (companyCode === '1018') {
    return Location.CN;
  }
  if (companyCode === '1086') {
    return Location.CZ;
  }
  return Location.OTHER;
};

export const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    function initMsalInstance(instance: IPublicClientApplication) {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }

      return instance.addEventCallback((event: EventMessage) => {
        const msalPayload = event.payload as AuthenticationResult;

        if (!msalPayload?.account || !msalPayload.accessToken) return;

        const account = msalPayload.account;
        instance.setActiveAccount(account);

        if (msalPayload.scopes && msalPayload.scopes.includes('User.Read')) {
          callMsGraph(msalPayload.accessToken).then((data) => {
            const user = {
              displayName: account.name,
              username: account.username,
              location: getLocation(data.onPremisesExtensionAttributes.extensionAttribute11),
              employeeId: data.employeeId,
              roles: account.idTokenClaims?.roles,
              isKioskServiceAccount: account.idTokenClaims?.roles?.includes('kiosk_service_account') || false,
              isManager: account.idTokenClaims?.roles?.includes('DK_Manager') || account.idTokenClaims?.roles?.includes('CN_Manager') || false,
              canImpersonate: account.idTokenClaims?.roles?.includes('impersonation') || false,
            };
            apm.setUserContext({
              username: account.username,
            });

            apm.addLabels({
              isKioskServiceAccount: user.isKioskServiceAccount,
              isManager: user.isManager,
            });

            if (window.location.host === 'localhost:3000') {
              user.location = Location.MX;
              user.isManager = false;
              user.isKioskServiceAccount = true;
            }
            if (user.isKioskServiceAccount) {
              changeLanguage('es');
              if (window.location.host === 'qa.test-ta-kiosk.legogroup.io' || window.location.host === 'prodcopy.test-ta-kiosk.legogroup.io') {
                user.location = Location.MX;
              }
            }
            setUser(user);
          });
        }
      });
    }
    const callbackId = initMsalInstance(msalInstance);
    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{ ...loginRequest }} errorComponent={ErrorPage}>
        <AuthContext.Provider
          value={{
            acquireTokenSilent: msalInstance.acquireTokenSilent,
            instance: msalInstance,
            user: user,
          }}>
          {children}
        </AuthContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export const useAuthData = () => useContext(AuthContext);
