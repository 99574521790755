import { KioskRouteProps } from '../../utils/types';
import { Home } from '../generics/Home';
import { ManagerHome } from '../manager/ManagerHome';
import { MXHome } from '../mx/MXHome';
import { MXTimeoff } from '../mx/MXTimeoff';
import { OvertimeRequests } from '../manager/OvertimeRequests';
import { Timeoff } from '../generics/Timeoff';
import { TimeSheets } from '../generics/TimeSheets';
import { Payslips } from '../mx/Payslips';
import { WorkLetter } from '../mx/WorkLetter';
import { EmployeeQuotas } from '../manager/EmployeeQuotas';
import { EmployeeAbsenceAttendance } from '../manager/EmployeeAbsenceAttendance';
import { EmployeeTimeEvents } from '../manager/EmployeeTimeEvents';
import { TimeEvents } from '../generics/TimeEvents';

export const DKManagerRoutes: KioskRouteProps[] = [
  { title: 'Navigation.home', path: '/', element: <ManagerHome /> },
  {
    title: 'Navigation.overtime_approval',
    path: '/overtime-approval',
    element: <OvertimeRequests />,
  },
  { title: 'Navigation.quotas', path: '/quotas', element: <EmployeeQuotas /> },
  {
    title: 'Navigation.absence_attendance',
    path: '/absence-attendance',
    element: <EmployeeAbsenceAttendance />,
  },
  {
    title: 'Navigation.time_events',
    path: '/time-events',
    element: <EmployeeTimeEvents />,
  },
];
export const CNManagerRoutes: KioskRouteProps[] = [
  { title: 'Navigation.home', path: '/', element: <ManagerHome /> },
  { title: 'Navigation.time_offs', path: '/time-offs', element: <Timeoff /> },
  { title: 'Navigation.time_events', path: '/time-events', element: <TimeEvents /> },
  { title: 'Navigation.quotas', path: '/quotas', element: <EmployeeQuotas /> },
  {
    title: 'Navigation.absence_attendance',
    path: '/absence-attendance',
    element: <EmployeeAbsenceAttendance />,
  },
];
export const HourlyRoutes: KioskRouteProps[] = [
  { title: 'Navigation.home', path: '/', element: <Home /> },
  { title: 'Navigation.time_offs', path: '/time-offs', element: <Timeoff /> },
  { title: 'Navigation.time_events', path: '/time-events', element: <TimeEvents /> },
];
export const MXRoutes: KioskRouteProps[] = [
  { title: 'Navigation.home', path: '/', element: <MXHome /> },
  { title: 'Navigation.time_offs', path: '/time-offs', element: <MXTimeoff /> },
  { title: 'Navigation.time_sheets', path: '/time-sheets', element: <TimeSheets /> },
  { title: 'Navigation.payslips', path: '/payslips', element: <Payslips /> },
  {
    title: 'Navigation.work_letters',
    dropdownItems: [
      {
        title: 'Navigation.with_salary',
        path: '/work-letter-with-salary',
        element: <WorkLetter withSalary={true} />,
      },
      {
        title: 'Navigation.without_salary',
        path: '/work-letter-without-salary',
        element: <WorkLetter withSalary={false} />,
      },
    ],
  },
];
export const NO_MATCH_ROUTE = '*';
