import { IPublicClientApplication } from '@azure/msal-browser';
import { APP_SCOPE, BASE_API_URL } from '../config/ConfigConstants';
import { formatToDateString } from '../datetime';

export const getDataByEmployee = async (
  instance: IPublicClientApplication,
  type: 'absenceAttendance' | 'quotas' | 'timeEvents',
  start: Date,
  end: Date,
  abortController: AbortController,
  employeeNumber?: string,
): Promise<any> => {
  const accessToken = await (await instance.acquireTokenSilent({ scopes: [APP_SCOPE] })).accessToken;
  const url = `${BASE_API_URL}/${type}/me?startDate=${formatToDateString(start)}&endDate=${formatToDateString(end)}${
    employeeNumber ? `&employeeNumber=${employeeNumber}` : ''
  }`;
  const headers: any = { authorization: `Bearer ${accessToken}` };
  const response = await fetch(url, {
    headers,
    signal: abortController?.signal,
  });
  const jsonResult = await response.json();

  return jsonResult.data;
};

export const getDataByManager = async (
  instance: IPublicClientApplication,
  type: 'absenceAttendance' | 'quotas' | 'timeEvents',
  start: Date,
  end: Date,
  abortController: AbortController,
): Promise<any> => {
  const accessToken = await (await instance.acquireTokenSilent({ scopes: [APP_SCOPE] })).accessToken;
  const url = `${BASE_API_URL}/${type}/manager?startDate=${formatToDateString(start)}&endDate=${formatToDateString(end)}`;
  const headers: any = { authorization: `Bearer ${accessToken}` };
  const response = await fetch(url, {
    headers,
    signal: abortController.signal,
  });
  const jsonResult = await response.json();

  return jsonResult.data;
};
