import { ArrowLongDownBold, ArrowLongUpBold, ArrowUpAndDownBold } from '@lego/klik-ui/icons';
import { Box, Flex, Table } from '@lego/klik-ui';
import { useState, useEffect } from 'react';
import { flexRender, Table as ReactTable, Column } from '@tanstack/react-table';
import { Loading } from '../pages/routes/Loading';

export interface DataTableProps {
  instance: ReactTable<any>;
  loading: boolean;
}

const renderSortIcon = (column: any) => {
  if (!column.getCanSort()) {
    return null;
  }
  let icon = <ArrowUpAndDownBold />;
  const s = column.getIsSorted();
  if (s === 'desc') {
    icon = <ArrowLongDownBold />;
  }
  if (s === 'asc') {
    icon = <ArrowLongUpBold />;
  }

  return (
    <Box fontSize='0.75rem' paddingEnd='1'>
      {icon}
    </Box>
  );
};

const Filter = ({ column }: { column: Column<any, unknown> }) => {
  const columnFilterValue = column.getFilterValue();
  return (
    <DebouncedInput
      style={{ width: '100%' }}
      value={columnFilterValue === undefined ? '' : columnFilterValue + ''}
      onChange={(value) => column.setFilterValue(value)}></DebouncedInput>
  );
};

// A debounced input react component
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder='type to filter...'
    />
  );
};

export const NewTable = ({ instance, loading }: DataTableProps) => {
  return (
    <Table mb={5} mt={2} size={'sm'}>
      <Table.Head>
        {instance.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.ColumnHeader key={header.id} color='light-blue.600' width={header.getSize()}>
                {header.isPlaceholder ? null : (
                  <Flex onClick={header.column.getToggleSortingHandler()} cursor='pointer'>
                    {renderSortIcon(header.column)}
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                )}
                {header.column.getCanFilter() ? (
                  <Flex>
                    <Filter column={header.column} />
                  </Flex>
                ) : null}
              </Table.ColumnHeader>
            ))}
          </Table.Row>
        ))}
      </Table.Head>
      <Table.Body>
        {loading && (
          <Table.Row>
            <Table.Cell colSpan={instance.getAllColumns().length}>
              <Loading></Loading>
            </Table.Cell>
          </Table.Row>
        )}
        {!loading &&
          instance.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
