import { Box, Flex, Table } from '@lego/klik-ui';
import { ArrowLongDownBold, ArrowLongUpBold, ArrowUpAndDownBold } from '@lego/klik-ui/icons';
import { HeaderGroup, TableInstance } from 'react-table';
import { Loading } from '../pages/routes/Loading';

const renderSortIcon = (column: any) => {
  if (!column.canSort) {
    return null;
  }

  if (!column.isSorted) {
    return <ArrowUpAndDownBold />;
  }

  if (column.isSortedDesc) {
    return <ArrowLongDownBold />;
  } else {
    return <ArrowLongUpBold />;
  }
};

export interface DataTableProps {
  instance: TableInstance<any>;
  loading: boolean;
}

export const DataTable = ({ instance, loading }: DataTableProps) => {
  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, columns, rows } = instance;
  return (
    <Table mb={5} mt={2} size={'sm'} {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup: HeaderGroup<any>) => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              return (
                <Table.ColumnHeader
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...column.parentProps}
                  color='light-blue.600'>
                  <Flex {...column.getSortByToggleProps()}>
                    {column.canSort ? (
                      <Box flex={column.isNumeric ? '1' : '0'} fontSize='0.75rem' paddingEnd='1'>
                        {renderSortIcon(column)}
                      </Box>
                    ) : null}
                    <Box>{column.render('Header')}</Box>
                  </Flex>
                </Table.ColumnHeader>
              );
            })}
          </Table.Row>
        ))}
      </Table.Head>
      <Table.Body {...getTableBodyProps()}>
        {loading && (
          <Table.Row>
            <Table.Cell colSpan={columns.length + 1}>
              <Loading></Loading>
            </Table.Cell>
          </Table.Row>
        )}
        {!loading &&
          rows.map((row) => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                ))}
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};
