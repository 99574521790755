import {
  Box,
  Text,
  HorizontalStack,
  Stack,
  List,
  Spacer,
  Divider,
  CircularProgress,
} from '@lego/klik-ui';
import { useTranslation } from 'react-i18next';
import { EmployeeQuotaViewerProps } from '../utils/types';

export const EmployeeQuotaViewer = ({ quotas, isLoading }: EmployeeQuotaViewerProps) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <Stack alignItems='center'>
        <CircularProgress mt={10} isIndeterminate={true} value={3} />
      </Stack>
    );
  }
  return (
    <Stack alignItems='center'>
      {quotas
        .sort((a, b) => a.endDate.localeCompare(b.endDate))
        .map((q) => {
          return (
            <Box
              bg={'slate.50'}
              borderRadius={5}
              borderWidth={1}
              borderColor='white'
              pl={5}
              pr={5}
              mb={2}
              key={q.type + q.startDate}>
              <Text bg={'slate.50'} as={'h4'} textStyle='h4' pt={2} pb={0} mb={0}>
                {q.name}
              </Text>
              <Text fontStyle={'italic'} fontSize={'xs'} mt={0} pt={0}>
                {t('Quota.valid-to')} {q.endDate}
              </Text>
              <Divider></Divider>
              <List>
                <List.Item fontSize={'sm'}>
                  <HorizontalStack>
                    <Text>{t('Quota.earned')}</Text>
                    <Spacer />
                    <Text>{parseFloat(q.daysEarned.toString()).toFixed(2)}</Text>
                  </HorizontalStack>
                </List.Item>
                <List.Item fontSize={'sm'}>
                  <HorizontalStack>
                    <Text>{t('Quota.used')}</Text>
                    <Spacer />
                    <Text>{parseFloat(q.daysUsed.toString()).toFixed(2)}</Text>
                  </HorizontalStack>
                </List.Item>
                <List.Item fontSize={'sm'}>
                  <HorizontalStack>
                    <Text>{t('Quota.remaining')}</Text>
                    <Spacer />
                    <Text fontWeight={'bold'}>
                      {parseFloat(q.daysRemaining.toString()).toFixed(2)}
                    </Text>
                  </HorizontalStack>
                </List.Item>
              </List>
            </Box>
          );
        })}
    </Stack>
  );
};
