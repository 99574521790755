import { CircularProgress, Stack, Text } from '@lego/klik-ui';
import { useEffect, useState } from 'react';
import { useAuthData } from '../../contexts/AuthProvider';
import { useKioskData } from '../../contexts/KioskDataProvider';
import { APP_SCOPE, BASE_API_URL } from '../../utils/config/ConfigConstants';

export interface WorkLetterProps {
  withSalary: boolean;
}

export const WorkLetter = (props: WorkLetterProps) => {
  const { instance } = useAuthData();
  const { employeeData } = useKioskData();
  const [pdf, setPdf] = useState<string>('');
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setFetching(true);
    instance
      .acquireTokenSilent({
        scopes: [APP_SCOPE],
      })
      .then((authResult) => {
        fetch(
          `${BASE_API_URL}/workletters?employeeNumber=${employeeData?.employeeNumber}&withsalary=${props.withSalary}`,
          {
            headers: {
              authorization: 'Bearer ' + authResult.accessToken,
            },
          },
        )
          .then((response) => response.blob())
          .then((blob) => {
            const file = window.URL.createObjectURL(blob);
            setPdf(file);
            setFetching(false);
          });
      });
  }, [instance, employeeData?.employeeNumber, props.withSalary]);
  let content = <span>Select a type</span>;
  if (fetching) {
    content = <CircularProgress isIndeterminate={true} value={3} />;
  } else if (pdf.length > 0) {
    content = (
      <embed
        src={pdf + '#&scrollbar=0'}
        title='workLetter'
        height={window.innerHeight - 170 + 'px'}
        width='100%'
      />
    );
  }

  return (
    <Stack>
      <Text as='h2' textStyle='h2'>
        Work letter {props.withSalary ? 'with' : 'without'} salary
      </Text>
      {content}
    </Stack>
  );
};
