import { Text } from '@lego/klik-ui';
import { Absence } from '@ta-global-kiosk/domain';
import { TableViewer } from '../../components/TableViewer';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { getDataByManager } from '../../utils/data/time';
import { useAuthData } from '../../contexts/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';

const columnHelper = createColumnHelper<Absence>();

export const EmployeeAbsenceAttendance = () => {
  const periodes = useMemo(() => {
    return getPeriodes().reverse();
  }, []);
  const [periode, setPeriode] = useState(periodes[periodes.length - 1].id);

  const { instance } = useAuthData();
  const [data, setData] = useState<Absence[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return getColumns(t);
  }, [t]);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      setIsLoading(true);
      try {
        const data = await getDataByManager(
          instance,
          'absenceAttendance',
          new Date(Number(periode), 0, 1),
          new Date(Number(periode), 11, 31),
          abortController,
        );
        setData(data);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getData();

    return () => {
      abortController.abort();
    };
  }, [instance, periode]);
  return (
    <>
      {/* Page header */}
      <Text as='h1' textStyle='h1' mt={5}>
        Absence and Attendance for your direct reports
      </Text>
      <PeriodeCarousel periodes={periodes} initialSelection={periodes.length - 1} onSelectionChange={(p) => setPeriode(p.id)} />
      <TableViewer data={data} columns={columns} isLoading={isLoading}></TableViewer>
    </>
  );
};

const getColumns = (t: any) => {
  return [
    columnHelper.accessor('employeeName', {
      header: () => t('Absence.employee'),
      minSize: 200,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('employeeNumber', {
      header: () => t('Absence.employee_number'),
      maxSize: 130,
      cell: (info) => info.getValue().replace(/^0+/, ''),
    }),
    columnHelper.accessor('sapId', {
      header: () => t('Absence.sap_id'),
      maxSize: 130,
      cell: (info) => info.getValue().replace(/^0+/, ''),
    }),
    columnHelper.accessor('name', {
      header: () => t('Absence.name'),
      maxSize: 150,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('type', {
      header: () => t('Absence.type'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('hours', {
      header: () => t('Absence.hours'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('days', {
      header: () => t('Absence.days'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('startDate', {
      header: () => t('Absence.from'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('endDate', {
      header: () => t('Absence.to'),
      maxSize: 100,
      cell: (info) => info.getValue(),
    }),
  ];
};

const getPeriodes = (): Periode[] => {
  const currentYear = new Date().getFullYear();
  return [
    {
      id: currentYear.toString(),
      display: currentYear.toString(),
      start: `${currentYear}-01-01`,
      end: `${currentYear}-12-31`,
    },
    {
      id: (currentYear - 1).toString(),
      display: (currentYear - 1).toString(),
      start: `${currentYear - 1}-01-01`,
      end: `${currentYear - 1}-12-31`,
    },
  ];
};
