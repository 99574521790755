import { Avatar, LEGOLogo, Menu, Navbar, Spacer } from '@lego/klik-ui';
import { ArrowDownBold, MenuNavigationVertical } from '@lego/klik-ui/icons';
import { EsSpain, GbUnitedKingdom, CnChina, HuHungary } from '@lego/klik-ui/flags';
import { useState } from 'react';
import { NavbarProps } from '../utils/types';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthData } from '../contexts/AuthProvider';
import { useKioskData } from '../contexts/KioskDataProvider';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

const LANGUAGES = [
  { label: 'English', value: 'en', flag: <GbUnitedKingdom mr={1} /> },
  { label: 'Español', value: 'es', flag: <EsSpain mr={1} /> },
  { label: 'Magyar', value: 'hu', flag: <HuHungary mr={1} /> },
  { label: '中文', value: 'cn', flag: <CnChina mr={1} /> },
];

const RightTopMenu = () => {
  const { instance, user } = useAuthData();
  const { isLoggedIn, employeeData, logout } = useKioskData();
  const { i18n } = useTranslation();

  if (user?.isKioskServiceAccount) {
    if (isLoggedIn) {
      return (
        <Menu>
          <Menu.Button>
            <Avatar size={'sm'} name={employeeData?.fullName} />
          </Menu.Button>
          <Menu.List color={'slate.800'}>
            <Menu.Divider />
            <Menu.Item onClick={() => logout()}>Logout</Menu.Item>
          </Menu.List>
        </Menu>
      );
    } else {
      return null;
    }
  } else {
    return (
      <>
        <Navbar.Item>
          <Menu>
            <Menu.Button>
              {LANGUAGES.find((l) => l.value === i18n.language)?.flag}
              <ArrowDownBold ml={1} />
            </Menu.Button>
            <Menu.List color={'slate.800'}>
              {LANGUAGES.map((l) => (
                <Menu.Item key={l.value} onClick={() => changeLanguage(l.value)}>
                  {l.flag}
                  {l.label}
                </Menu.Item>
              ))}
            </Menu.List>
          </Menu>
        </Navbar.Item>

        <Menu>
          <Menu.Button>
            <Avatar size={'sm'} name={user?.displayName} />
          </Menu.Button>
          <Menu.List color={'slate.800'}>
            <Menu.Divider />
            <Menu.Item onClick={() => instance.logout()}>Logout</Menu.Item>
          </Menu.List>
        </Menu>
      </>
    );
  }
};

export const NavbarContainer = (props: NavbarProps) => {
  const [activeItem, setActiveItem] = useState(0);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const navbarItems = props.navItems.map((item, index) => {
    if (item.dropdownItems) {
      return (
        <Navbar.Item
          hasMenu={true}
          title={t(item.title)}
          isActive={activeItem === index}
          key={`#navItem-${index}`}
          icon={ArrowDownBold}>
          {item.dropdownItems.map((option, optionIndex) => {
            return (
              <Navbar.Option
                key={`#navItem-${index}-${optionIndex}`}
                as={NavLink}
                data-transaction-name={option.path?.slice(1)}
                onClick={() => setActiveItem(index)}
                to={option.path!}>
                {t(option.title)}
              </Navbar.Option>
            );
          })}
        </Navbar.Item>
      );
    } else {
      return (
        <Navbar.Item
          as={NavLink}
          data-transaction-name={item.path?.slice(1)}
          isActive={pathname === item.path!}
          key={`#navItem-${index}`}
          to={item.path!}
          onClick={() => setActiveItem(index)}>
          {t(item.title)}
        </Navbar.Item>
      );
    }
  });

  return (
    <Navbar>
      <Navbar.Brand href='/'>
        <LEGOLogo mr='10px' />
        Kiosk
      </Navbar.Brand>
      <Navbar.Segment display={{ base: 'none', lg: 'flex' }}>{navbarItems}</Navbar.Segment>
      <Spacer />
      <Navbar.Segment display={{ base: 'flex', lg: 'none' }}>
        <Navbar.Item hasMenu={true} icon={MenuNavigationVertical}>
          {navbarItems}
        </Navbar.Item>
      </Navbar.Segment>
      <Navbar.Segment display={{ base: 'none', lg: 'flex' }}>
        <RightTopMenu />
      </Navbar.Segment>
    </Navbar>
  );
};
