import { Stack, Text, Image } from '@lego/klik-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useKioskData } from '../../contexts/KioskDataProvider';
import hand from '../../assets/Hand.png';
import { Loading } from '../routes/Loading';
import { ENVIRONMENT } from '../../utils/config/ConfigConstants';

const TIME_OUT_TIME = ENVIRONMENT === 'prod' ? 200 : 1500;
const REGEX = /^[0-9]+$/;
const REFRESH_TIME = 1000 * 60 * 60;

export const Login = () => {
  const [badge, setBadge] = useState('');
  const { login, isLoggedIn, fetching } = useKioskData();
  const keyInputTimeout = useRef<NodeJS.Timeout | undefined>();
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      let newBadge: string = badge;
      if (REGEX.test(e.key)) {
        newBadge = badge + e.key;
        if (REGEX.test(newBadge)) {
          setBadge(newBadge);
        }
        if (keyInputTimeout.current) clearTimeout(keyInputTimeout.current);
        keyInputTimeout.current = setTimeout(() => {
          setBadge('');
          login(newBadge);
        }, TIME_OUT_TIME);
      }
    },
    [setBadge, badge, login],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress);
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, REFRESH_TIME);
    return () => clearInterval(interval);
  }, []);

  if (isLoggedIn && fetching) {
    return <Loading info='Iniciando sesión, por favor espera...'></Loading>;
  }

  return (
    <Stack direction='row'>
      <Stack alignContent='center' pt={100}>
        <Text as='h1' textStyle='h1'>
          <u>Scan Your Card</u>
        </Text>
        <Text textColor='light-blue.500' fontWeight='bold'>
          REMEMBER:{' '}
        </Text>
        <Text>
          Your employee card is personal, do not lend it out to others.
          <br />
          If you lose your employee card, report it to HR immediately to block access.
          <br />
          Remember that your information is confidential.
          <br />
          If there is a problem with the Kiosk, report it to IT or HR.
        </Text>
        <Text>
          Tu tarjeta de empleado es personal, no la prestes a otros.
          <br />
          Si pierde su tarjeta de empleado, repórtelo a Recursos Humanos inmediatamente para bloquear el acceso.
          <br />
          Recuerda que tu información es confidencial.
          <br />
          Si hay un problema con el quiosco, repórtelo a IT o HR.
        </Text>
      </Stack>
      <Image alt='badge scan image' src={hand} />
    </Stack>
  );
};
