import { useEffect, useState } from 'react';
import { useAuthData } from '../../contexts/AuthProvider';

export enum ResultType {
  JSON,
  BLOB,
}
interface UseGetProps {
  url?: string;
  authScopes?: string[];
  options?: RequestInit;
  resultType: ResultType;
}

export const useGet = ({ url, options, authScopes, resultType }: UseGetProps) => {
  const { instance } = useAuthData();

  // state
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      if (url) {
        setIsLoading(true);
        setError(null);
        setData(null);
        try {
          let authHeader: any = {};
          if (authScopes) {
            const token = await (await instance.acquireTokenSilent({ scopes: authScopes })).accessToken;
            authHeader.authorization = `Bearer ${token}`;
          }

          const response = await fetch(url, {
            ...options,
            headers: {
              ...authHeader,
            },
            signal: abortController.signal,
          });

          if (!response.ok) {
            // this will be handled by our `catch` block below
            throw new Error(`Request Error: ${response.status}`);
          }

          if (resultType === ResultType.JSON) setData(await response.json());
          if (resultType === ResultType.BLOB) setData(await response.blob());
          setIsLoading(false);
        } catch (e: any) {
          if (e.name === 'AbortError') {
            return;
          }
          setError(e.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, [url, authScopes, instance, options, resultType]);

  // return the `state` so it can be accessed by the component that uses this hook.
  return {
    isLoading,
    data,
    error,
  };
};
