import { init as initApm } from '@elastic/apm-rum';
import { BASE_API_URL, ENVIRONMENT } from '../config/ConfigConstants';
var apm = initApm({
  environment: ENVIRONMENT,
  serviceName: 'KIOSK WebApp',
  serverUrl: 'https://13c6d2cc63224937a3d1dd8ef98468fd.ece.legogroup.io:9243',
  distributedTracingOrigins: [BASE_API_URL],
  distributedTracing: true,
  propagateTracestate: true,
});

export default apm;
