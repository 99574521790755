import { Box, HorizontalStack, Spacer, Stack, Table, Text } from '@lego/klik-ui';
import { LogIn, LogOut } from '@lego/klik-ui/icons';
import { TimeEvent } from '@ta-global-kiosk/domain';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { generateDaysInWeek } from '../utils/datetime';
import { useMemo } from 'react';
import { Loading } from '../pages/routes/Loading';

interface TimeEventWeekViewProps {
  data: TimeEvent[];
  periode: string;
  isLoading: boolean;
}

interface TimeEventCalendarDisplayProps {
  event: TimeEvent;
}

export const TimeEventWeekView = ({ data, isLoading, periode }: TimeEventWeekViewProps) => {
  const { t } = useTranslation();
  const days = useMemo(() => generateDaysInWeek(periode), [periode]);

  const groupByEmployee = groupBy(data, 'employeeNumber');

  const employeeNumbers = Object.keys(groupByEmployee);

  return (
    <>
      {isLoading && <Loading></Loading>}
      {!isLoading && (
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.ColumnHeader color='light-blue.600' width={250}>
                {t('TimeEvent.employee')}
              </Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.monday')}</Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.tuesday')}</Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.wednesday')}</Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.thursday')}</Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.friday')}</Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.saturday')}</Table.ColumnHeader>
              <Table.ColumnHeader color='light-blue.600'>{t('Days.sunday')}</Table.ColumnHeader>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {employeeNumbers.map((employeeNumber) => {
              const timeEvents = groupByEmployee[employeeNumber];
              const groupByDate = groupBy(timeEvents, 'logicalDate');
              return (
                <Table.Row key={timeEvents[0].employeeNumber || timeEvents[0].sapId}>
                  <Table.Cell>
                    <Stack spacing='0'>
                      <Text color='slate.600'>{`${
                        timeEvents[0].employeeNumber
                      } (${timeEvents[0].sapId.replace(/^0+/, '')})`}</Text>
                      <Text>{timeEvents[0].employeeName}</Text>
                    </Stack>
                  </Table.Cell>
                  {days.map((day) => {
                    return (
                      <Table.Cell key={employeeNumber + '#' + day} p={2}>
                        <Stack spacing={1}>
                          {groupByDate[day]?.map((timeEvent) => {
                            return <TimeEventCalendarDisplay event={timeEvent} />;
                          })}
                        </Stack>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};

export const TimeEventCalendarDisplay = ({ event }: TimeEventCalendarDisplayProps) => {
  let color = '';
  let icon;
  if (event.type === 'P10') {
    //display = 'Punch In';
    color = 'success.100';
    icon = <LogIn />;
  } else if (event.type === 'P20') {
    //display = 'Punch Out';
    color = 'error.100';
    icon = <LogOut />;
  } else {
    color = 'slate.100';
  }
  return (
    <Box backgroundColor={color} borderRadius={2} pr={2} pl={2} w={130}>
      <HorizontalStack spacing={1}>
        <Text>{event.logicalTime.substring(0, 5)}</Text>
        {event.reason !== '' && <Text>({event.reason})</Text>}
        <Spacer />
        {icon}
      </HorizontalStack>
    </Box>
  );
};
