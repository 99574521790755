import { OvertimeRecord } from '@ta-global-kiosk/domain';
import { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { getOvertime } from '../utils/data/overtime';
import { Location } from '../utils/types';

import { useAuthData } from './AuthProvider';

export type TManagerDataContext = {
  fetching: boolean;
  overtimeData: OvertimeRecord[];
  currentPayPeriod: PayPeriod;
  reloadData: () => void;
};

export interface PayPeriod {
  name: string;
  start: Date;
  end: Date;
  keyDate: Date;
}

const getPayperiodForToday = (): PayPeriod => {
  const today = new Date();

  if (today.getDate() < 11) {
    today.setMonth(today.getMonth() - 1);
  }
  const end = new Date(today);
  end.setDate(19);
  const start = new Date(today);
  start.setMonth(today.getMonth() - 1);
  start.setDate(20);
  const name = `${today.toLocaleString('en-us', { month: 'long' })}`;
  const keyDate = new Date(end);
  keyDate.setDate(21);

  return {
    name,
    start,
    end,
    keyDate,
  };
};

const ManagerDataContext = createContext<any>({});

export const ManagerDataProvider = ({ children }: any) => {
  const [fetching, setFetching] = useState(true);
  const [overtimeData, setOvertimeData] = useState<OvertimeRecord[]>([]);

  const currentPayPeriod = useMemo(getPayperiodForToday, []);

  const { instance, user } = useAuthData();

  const reloadData = useCallback(() => {
    if (user?.location === Location.DK) {
      const p1 = getOvertime(instance, user!, currentPayPeriod)
        .then((data) => setOvertimeData(data))
        .catch((ex) => console.error(ex));

      Promise.all([p1]).finally(() => setFetching(false));
    } else {
      setFetching(false);
    }
  }, [instance, user, currentPayPeriod]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  return (
    <ManagerDataContext.Provider
      value={{
        fetching,
        overtimeData,
        reloadData,
        currentPayPeriod,
      }}>
      {children}
    </ManagerDataContext.Provider>
  );
};

export const useManagerData = () => useContext<TManagerDataContext>(ManagerDataContext);
