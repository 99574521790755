import {
  Button,
  Checkbox,
  Text,
  Flex,
  Stack,
  useDisclosure,
  Overlay,
  Spinner,
} from '@lego/klik-ui';
import { CheckTickBold, CrossBold } from '@lego/klik-ui/icons';
import { useMemo } from 'react';
import {
  Column,
  UseRowSelectState,
  useRowSelect,
  useTable,
  UseRowSelectInstanceProps,
  TableInstance,
} from 'react-table';
import { useAuthData } from '../../contexts/AuthProvider';
import { getRecordHash, putOvertime } from '../../utils/data/overtime';

import { OvertimeRecord } from '@ta-global-kiosk/domain';
import { parseDate } from '../../utils/datetime';
import { useSortBy } from 'react-table';
import { DataTable } from '../../components/Table';
import { useManagerData } from '../../contexts/ManagerDataProvider';

export const OvertimeRequests = () => {
  const columns: Column<OvertimeRecord>[] = useMemo<Column<OvertimeRecord>[]>(
    () => [
      {
        Header: 'Employee',
        accessor: 'employeeName',
        Cell: ({ row }: any) => {
          const { employeeName, employeeNumber } = row.original;
          return (
            <Stack spacing='1'>
              <Text color='slate.600'>{employeeNumber.replace(/^0+/, '')}</Text>
              <Text>{employeeName}</Text>
            </Stack>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: (row) => {
          return <span>{parseDate(row.value)!.toLocaleDateString()}</span>;
        },
      },
      {
        Header: 'Description',
        accessor: 'wageTypeDescription',
      },
      {
        Header: 'Start',
        accessor: 'startTime',
        Cell: (row) => {
          let content = '';
          if (row.value && row.value.length > 0) {
            content = `${row.value.substring(0, 2)}:${row.value.substring(2, 4)}`;
          }
          return <span>{content}</span>;
        },
      },
      {
        Header: 'End',
        accessor: 'endTime',
        Cell: (row) => {
          let content = '';
          if (row.value && row.value.length > 0) {
            content = `${row.value.substring(0, 2)}:${row.value.substring(2, 4)}`;
          }
          return <span>{content}</span>;
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [],
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { instance } = useAuthData();
  const { overtimeData, fetching, currentPayPeriod, reloadData } = useManagerData();

  const tableInstance: TableInstance<OvertimeRecord> = useTable<OvertimeRecord>(
    {
      columns,
      data: overtimeData,
      getRowId: (originalRow) => getRecordHash(originalRow),
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          parentProps: {
            width: '40px',
          },
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const { checked, indeterminate, ...rest } = getToggleAllRowsSelectedProps();
            return (
              <Checkbox
                isChecked={checked}
                isIndeterminate={indeterminate}
                sx={{ verticalAlign: 'middle' }}
                {...rest}
              />
            );
          },

          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: any) => {
            const { checked, indeterminate, ...rest } = row.getToggleRowSelectedProps();
            return <Checkbox isChecked={checked} sx={{ verticalAlign: 'middle' }} {...rest} />;
          },
        },
        ...columns,
      ]);
    },
  );

  const { selectedFlatRows, state } = tableInstance as TableInstance<OvertimeRecord> &
    UseRowSelectInstanceProps<OvertimeRecord>;

  const updateSelectedRecords = async (approved: boolean) => {
    try {
      onOpen();
      let success = true;
      for (var i in selectedFlatRows) {
        console.log(selectedFlatRows[i].original);

        success =
          success &&
          (await putOvertime(instance, {
            ...selectedFlatRows[i].original,
            approved: approved ? 'Y' : 'N',
          }));
      }
      onClose();
      reloadData();
    } catch (ex) {
      console.error(ex);
    }
  };

  const numberOfSelectedRows = Object.keys(
    (state as UseRowSelectState<OvertimeRecord>).selectedRowIds,
  ).length;
  const batchActionsForRowsDisabled = numberOfSelectedRows === 0;

  return (
    <>
      {/* Page header */}
      <Text as='h1' textStyle='h1' mt={5}>
        Overtime approval
      </Text>
      <Text mt={2}>
        <b>Period: </b> {currentPayPeriod.name} ({currentPayPeriod.start.toLocaleDateString()} -{' '}
        {currentPayPeriod.end.toLocaleDateString()})
      </Text>
      <Text mt={2}>
        Please select one or more rows to approve or reject the overtime for employees.
      </Text>

      {/* Toolbar */}
      <Flex mt={5}>
        {!batchActionsForRowsDisabled && (
          <Text as='p' p={1} fontSize='md' textAlign='center'>
            {numberOfSelectedRows} selected
          </Text>
        )}

        <Button
          data-transaction-name='approve-overtime'
          size='sm'
          variant='ghost'
          color='success.400'
          disabled={batchActionsForRowsDisabled}
          leftIcon={<CheckTickBold />}
          onClick={() => {
            updateSelectedRecords(true);
          }}>
          Approve
        </Button>
        {!batchActionsForRowsDisabled && (
          <Text as='p' p={1} fontSize='md' textAlign='center'>
            or
          </Text>
        )}
        <Button
          data-transaction-name='reject-overtime'
          size='sm'
          variant='ghost'
          color='error.400'
          disabled={batchActionsForRowsDisabled}
          leftIcon={<CrossBold />}
          onClick={() => {
            updateSelectedRecords(false);
          }}>
          Reject
        </Button>
        {!batchActionsForRowsDisabled && (
          <Text as='p' p={1} fontSize='md' textAlign='center'>
            ?
          </Text>
        )}
      </Flex>

      {/* Table */}
      <DataTable instance={tableInstance} loading={fetching}></DataTable>

      {/* Overlay for processing */}
      <Overlay isCentered={true} isOpen={isOpen} variant='light'>
        <Spinner /> Updating...
      </Overlay>
    </>
  );
};
