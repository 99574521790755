import { Router } from './pages/routes/Router';
import { useAuthData } from './contexts/AuthProvider';
import { ManagerDataProvider } from './contexts/ManagerDataProvider';
import { KioskDataProvider } from './contexts/KioskDataProvider';
import { Loading } from './pages/routes/Loading';
import { Location } from './utils/types';
import { EmployeeDataProvider } from './contexts/EmployeeDataProvider';

export const App = () => {
  const { user } = useAuthData();
  return (
    <>
      {!user && <Loading></Loading>}
      {user && user.location === Location.MX && (
        <KioskDataProvider>
          <Router />
        </KioskDataProvider>
      )}
      {user &&
        user.location !== Location.MX &&
        (user!.isManager ? (
          <ManagerDataProvider>
            <Router />
          </ManagerDataProvider>
        ) : (
          <EmployeeDataProvider>
            <Router />
          </EmployeeDataProvider>
        ))}
    </>
  );
};
