import { Spinner, Stack, Text } from '@lego/klik-ui';

interface LoadingProps {
  info?: string;
}

export const Loading = ({ info }: LoadingProps) => {
  return (
    <Stack spacing={1} alignItems='center'>
      <Spinner variant='lg' />
      {info && <Text>{info}</Text>}
    </Stack>
  );
};
