import { EmployeeQuotaViewer } from '../../components/EmployeeQuotaViewer';
import { EmployeeAbsenceViewer } from '../../components/EmployeeAbsenceViewer';
import { HorizontalStack, Stack, Box } from '@lego/klik-ui';
import { getDataByEmployee } from '../../utils/data/time';
import { useAuthData } from '../../contexts/AuthProvider';
import { Absence, Quota } from '@ta-global-kiosk/domain';
import { useEffect, useMemo, useState } from 'react';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';
import { useKioskData } from '../../contexts/KioskDataProvider';
import { generateYearPeriods } from '../../utils/datetime';

const PAST_PERIODES = 3;

export const MXTimeoff = () => {
  const periodes: Periode[] = useMemo(() => {
    return generateYearPeriods(PAST_PERIODES).reverse();
  }, []);

  const [year, setYear] = useState(new Date().getFullYear());
  const { instance, user } = useAuthData();
  const { employeeData } = useKioskData();
  const [quotaData, setQuotaData] = useState<Quota[]>([]);
  const [isLoadingQuotas, setIsLoadingQuotas] = useState(true);
  const [absenceData, setAbsenceData] = useState<Absence[]>([]);
  const [isLoadingAbsences, setIsLoadingAbsences] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    setQuotaData([]);
    setIsLoadingQuotas(true);
    const getQuotaData = async () => {
      //for quotas we only fetch for current year always.
      const today = new Date();
      try {
        const quotaData = await getDataByEmployee(
          instance,
          'quotas',
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
          abortController,
          user?.isKioskServiceAccount ? employeeData?.employeeNumber : undefined,
        );
        setQuotaData(quotaData);
        setIsLoadingQuotas(false);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      }
    };

    getQuotaData();

    return () => {
      abortController.abort();
    };
  }, [instance, user?.isKioskServiceAccount, employeeData?.employeeNumber]);

  useEffect(() => {
    const abortController = new AbortController();
    setAbsenceData([]);
    setIsLoadingAbsences(true);
    const getAbsenceData = async () => {
      try {
        const absenceData = await getDataByEmployee(
          instance,
          'absenceAttendance',
          new Date(year, 0, 1),
          new Date(year, 11, 31),
          abortController,
          user?.isKioskServiceAccount ? employeeData?.employeeNumber : undefined,
        );
        setAbsenceData(absenceData);
        setIsLoadingAbsences(false);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      }
    };

    getAbsenceData();

    return () => {
      abortController.abort();
    };
  }, [instance, user?.isKioskServiceAccount, employeeData?.employeeNumber, year]);

  return (
    <HorizontalStack align={'flex-start'} spacing={5} divider={<Stack.Divider orientation='vertical' />} m={4}>
      <Box w={{ base: '45%', md: '35%' }}>
        <EmployeeQuotaViewer
          quotas={quotaData.filter((quota) => {
            return quota.type === '15';
          })}
          isLoading={isLoadingQuotas}
        />
      </Box>
      <Box w={'100%'}>
        <PeriodeCarousel periodes={periodes} initialSelection={periodes.length - 1} onSelectionChange={(p) => setYear(Number(p.id))} />
        <EmployeeAbsenceViewer absences={absenceData.filter((absence) => absence.type === '0010')} isLoading={isLoadingAbsences} />
      </Box>
    </HorizontalStack>
  );
};
