import { IPublicClientApplication } from '@azure/msal-browser';
import { OvertimeRecord } from '@ta-global-kiosk/domain';
import { PayPeriod } from '../../contexts/ManagerDataProvider';
import { BASE_API_URL, OVERTIME_SCOPE } from '../config/ConfigConstants';
import { IUser } from '../types';

export const getRecordHash = (record: OvertimeRecord) => {
  return `${record.employeeNumber}${record.date}${record.seqNr}${record.wageType}${record.amount}`;
};

export const getOvertime = async (
  instance: IPublicClientApplication,
  user: IUser,
  payPeriod: PayPeriod,
): Promise<OvertimeRecord[]> => {
  const accessToken = (await instance.acquireTokenSilent({ scopes: [OVERTIME_SCOPE] })).accessToken;
  const url = `${BASE_API_URL}/overtime?pernr=${
    user.employeeId
  }&keydate=${payPeriod.keyDate.toISOString()}`;
  const response = await fetch(url, {
    headers: { authorization: `Bearer ${accessToken}` },
  });
  const jsonResult = await response.json();

  return jsonResult;
};

export const putOvertime = async (
  instance: IPublicClientApplication,
  record: OvertimeRecord,
): Promise<boolean> => {
  const accessToken = (await instance.acquireTokenSilent({ scopes: [OVERTIME_SCOPE] })).accessToken;
  const response = await fetch(BASE_API_URL + '/overtime', {
    method: 'PUT',
    body: JSON.stringify(record),
    headers: { authorization: `Bearer ${accessToken}` },
  });

  return response.status === 204;
};
