import { Configuration, LogLevel } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: (window as any).appConfig.appClientId,
    authority: 'https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Error,
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    'openid',
    'profile',
    'User.Read',
    // 'api://1e31037c-295d-496a-bb34-cdd0636962fe/user_impersonation',
  ],
};

export const graphConfig = {
  graphMeEndpoint:
    'https://graph.microsoft.com/beta/me?$select=employeeId,onPremisesExtensionAttributes',
};
