import { Box, Card, Grid, Skeleton, SkeletonText, Text } from '@lego/klik-ui';
import { Quota } from '@ta-global-kiosk/domain';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthData } from '../../contexts/AuthProvider';
import { useManagerData } from '../../contexts/ManagerDataProvider';
import { getDataByManager } from '../../utils/data/time';
import { Location } from '../../utils/types';

export const ManagerHome = () => {
  const { user } = useAuthData();

  return (
    <Grid mt={10} gap={4} h='800px' w='100%' templateColumns='repeat(6, 1fr)' templateRows='repeat(3, 1fr)'>
      <Grid.Item colSpan={4} rowSpan={3}>
        <Text as='h2' textStyle='h2'>
          Welcome {user?.displayName}
        </Text>
      </Grid.Item>
      <Grid.Item colSpan={1} rowSpan={1}>
        {user?.location === Location.DK && <OvertimeCard />}
      </Grid.Item>
      <Grid.Item colSpan={1} rowSpan={1}>
        {user?.location === Location.DK && <QuotasCard />}
      </Grid.Item>
    </Grid>
  );
};

const groupBy = (array: Array<any>, key: string) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const QuotasCard = () => {
  const { instance } = useAuthData();
  const [quotasEndingThisYear, setQuotasEndingThisYear] = useState<Quota[]>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      setFetching(true);
      const year = new Date().getFullYear();
      const quotaData = await getDataByManager(instance, 'quotas', new Date(year, 0, 1), new Date(year, 11, 31), abortController);
      const grouped = groupBy(
        quotaData.filter((q: Quota) => {
          return (
            (q.name === 'Ferie' || q.name === 'Feriefritimer' || q.name === 'Overført ferie') &&
            q.daysRemaining > 0 &&
            q.endDate === new Date().getFullYear() + '-12-31'
          );
        }),
        'employeeNumber',
      );
      setQuotasEndingThisYear(grouped);
      setFetching(false);
    };

    getData();
    return () => {
      abortController.abort();
    };
  }, [instance]);
  return (
    <Card width='xs' minHeight={'30%'}>
      <Box m='3'>
        {fetching ? (
          <Skeleton height='32px'></Skeleton>
        ) : (
          <Text as='h4' textStyle='h4' mb={2}>
            Quotas
          </Text>
        )}
        {fetching && <SkeletonText mt={2} noOfLines={2} skeletonHeight='1rem' spacing='2' />}
        {!fetching && Object.keys(quotasEndingThisYear).length > 0 && (
          <>
            <Text as='p' textStyle='body1'>
              {Object.keys(quotasEndingThisYear).length} employee with leftover quota ending this year.
            </Text>
            <Text mt={2} color='#4599de'>
              <Link to='/quotas' data-transaction-name='quotas'>
                See more
              </Link>
            </Text>
          </>
        )}
      </Box>
    </Card>
  );
};

const OvertimeCard = () => {
  const { fetching, overtimeData } = useManagerData();
  const actionableOvertime = useMemo(
    () =>
      overtimeData.filter((r) => {
        return r.approved !== 'Y' && r.approved !== 'N';
      }),
    [overtimeData],
  );

  return (
    <Card width='xs' minHeight={'30%'}>
      <Box m='3'>
        {fetching ? (
          <Skeleton height='32px'></Skeleton>
        ) : (
          <Text as='h4' textStyle='h4' mb={2}>
            Overtime
          </Text>
        )}
        {fetching && <SkeletonText mt={2} noOfLines={2} skeletonHeight='1rem' spacing='2' />}
        {!fetching && actionableOvertime.length > 0 && (
          <Text as='p' textStyle='body1'>
            <Link to='/overtime-approval' data-transaction-name='overtime-approval'>
              Your have {actionableOvertime.length} missing approval.
            </Link>
          </Text>
        )}
        {!fetching && actionableOvertime.length === 0 && (
          <Text as='p' textStyle='body1'>
            Your currently have no pending overtime approvals.
          </Text>
        )}
      </Box>
    </Card>
  );
};
