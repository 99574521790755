import { Box, Avatar, Text, Stack, HorizontalStack, List, CircularProgress, Tabs, Divider } from '@lego/klik-ui';
import { EmployeeData, FamilyMember } from '@ta-global-kiosk/domain';
import { formatLocalFromUTC, parseDate } from '../../utils/datetime';
import { useKioskData } from '../../contexts/KioskDataProvider';
import { FactoryBuilding, LegoUserFaceless, MultipleUsers } from '@lego/klik-ui/icons';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const MXHome = () => {
  const { employeeData, fetching } = useKioskData();
  const { t } = useTranslation();

  return (
    <HorizontalStack align={'flex-start'} divider={<Stack.Divider orientation='vertical' />} p='1'>
      {fetching ? (
        <CircularProgress isIndeterminate={true} value={3} />
      ) : (
        <>
          <Box textAlign={'center'} w={{ base: '40%', md: '30%' }} mt={5}>
            <Avatar
              size={{ base: 'md', md: '2xl' }}
              mb={5}
              src={'http://dkaisw.corp.lego.com/employeeimage_open/getImage.aspx?id=' + employeeData?.employeeNumber}
            />
            <Text fontWeight='semibold' fontSize={{ base: 'sm', md: 'lg' }}>
              {employeeData?.fullName || employeeData?.firstName + ' ' + employeeData?.lastName}
            </Text>
          </Box>

          <Box w={'100%'} m={4} ml={{ base: 2, md: 20 }}>
            <Tabs size='md'>
              <Tabs.TabList>
                <Tabs.Tab leftIcon={<LegoUserFaceless />}>{t('MXHome.tab1')}</Tabs.Tab>
                <Tabs.Tab leftIcon={<FactoryBuilding />}>{t('MXHome.tab2')}</Tabs.Tab>
                <Tabs.Tab leftIcon={<MultipleUsers />}>{t('MXHome.tab3')}</Tabs.Tab>
              </Tabs.TabList>
              <Tabs.TabPanels>
                <Tabs.TabPanel>{PersonalInfo(employeeData!)}</Tabs.TabPanel>
                <Tabs.TabPanel>{EmploymentInfo(employeeData!)}</Tabs.TabPanel>
                <Tabs.TabPanel>{FamilyInfo(employeeData!)}</Tabs.TabPanel>
              </Tabs.TabPanels>
            </Tabs>
          </Box>
        </>
      )}
    </HorizontalStack>
  );
};

interface KeyValueProps {
  label: string;
  value: string | undefined;
}

const KeyValueListItem = memo(({ label, value }: KeyValueProps) => {
  return (
    <List.Item key={label}>
      <Stack spacing={0}>
        <Text m={0} as='span' color='slate.400' fontSize='xs'>
          {label}
        </Text>
        <Text m={0} as='span' fontSize='md'>
          {value}&nbsp;
        </Text>
      </Stack>
      <Divider orientation='horizontal' />
    </List.Item>
  );
});

const PersonalInfo = (data: EmployeeData) => {
  const items = [
    { label: 'Gender', value: data.gender },
    {
      label: 'Date of Birth',
      value: formatLocalFromUTC(parseDate(data.birthDate)),
    },
    { label: 'R.F.C.', value: data.rfc },
    { label: 'C.U.R.P.', value: data.nationalId },
    { label: 'S.S.N.', value: data.ssi },
    { label: 'Street', value: data.street },
    { label: 'District', value: data.district },
    { label: 'City', value: data.city },
    { label: 'Postal Code', value: data.postalCode },
  ];
  return (
    <List spacing={1}>
      {items.map((item) => (
        <KeyValueListItem key={item.label} label={item.label} value={item.value} />
      ))}
    </List>
  );
};
const EmploymentInfo = (data: EmployeeData) => {
  const items = [
    {
      label: 'Hire Date',
      value: formatLocalFromUTC(parseDate(data.hireDate)),
    },
    { label: 'Seniority', value: data.seniority + ' years' },
    { label: 'Work Schedule', value: data.workSchedule },
    { label: 'Company', value: data.company },
  ];
  return (
    <List spacing={1}>
      {items.map((item) => (
        <KeyValueListItem key={item.label} label={item.label} value={item.value} />
      ))}
    </List>
  );
};

const FamilyInfo = (data: EmployeeData) => {
  return (
    <List spacing={1}>
      {data.family && data.family.map((f: FamilyMember) => <KeyValueListItem key={f.fullName} label={f.relation} value={f.fullName} />)}
    </List>
  );
};
