import React from 'react';
import ReactDOM from 'react-dom/client';

import { AuthProvider } from './contexts/AuthProvider';
import { createStandaloneToast, KlikProvider } from '@lego/klik-ui';
import { App } from './App';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const { ToastContainer } = createStandaloneToast();

root.render(
  <React.StrictMode>
    <AuthProvider>
      <KlikProvider includeFont={true}>
        <App />
        <ToastContainer />
      </KlikProvider>
    </AuthProvider>
  </React.StrictMode>,
);
