import { CloseButton, CustomUseToastOptions, InlineNotification } from '@lego/klik-ui';

interface CreateNotificationProps {
  variant: 'info' | 'warning' | 'error' | 'success' | 'plain';
  title: string;
  description?: string;
}

export const createNotification = (props: CreateNotificationProps): CustomUseToastOptions => {
  return {
    position: 'top-right',
    render: ({ onClose }) => (
      <InlineNotification variant={props.variant}>
        <InlineNotification.Content alignItems='flex-start' flexDirection='column'>
          <InlineNotification.Title>{props.title}</InlineNotification.Title>
          {props.description && (
            <InlineNotification.Description>{props.description}</InlineNotification.Description>
          )}
        </InlineNotification.Content>
        <CloseButton aria-label='Close' onClick={onClose} />
      </InlineNotification>
    ),
  };
};
