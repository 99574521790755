import { EmployeeQuotaViewer } from '../../components/EmployeeQuotaViewer';
import { HorizontalStack, Stack, Box } from '@lego/klik-ui';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';
import { getDataByEmployee } from '../../utils/data/time';
import { useEffect, useMemo, useState } from 'react';
import { useAuthData } from '../../contexts/AuthProvider';
import { Absence, Quota } from '@ta-global-kiosk/domain';
import { TableViewer } from '../../components/TableViewer';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { formatLocalFromUTC, generateYearPeriods, parseDate } from '../../utils/datetime';

const PAST_PERIODES = 3;

const columnHelper = createColumnHelper<Absence>();

export const Timeoff = () => {
  const { t } = useTranslation();

  const periodes: Periode[] = useMemo(() => {
    return generateYearPeriods(PAST_PERIODES).reverse();
  }, []);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('startDate', { header: t('Absence.start'), cell: (cell) => formatLocalFromUTC(parseDate(cell.getValue())) }),
      columnHelper.accessor('endDate', { header: t('Absence.end'), cell: (cell) => formatLocalFromUTC(parseDate(cell.getValue())) }),
      columnHelper.accessor('hours', { header: t('Absence.hours') }),
      columnHelper.accessor('name', { header: t('Absence.name') }),
    ];
  }, [t]);

  const [year, setYear] = useState(new Date().getFullYear());
  const { instance } = useAuthData();

  const [quotaData, setQuotaData] = useState<Quota[]>([]);
  const [isLoadingQuotas, setIsLoadingQuotas] = useState(true);
  const [absenceData, setAbsenceData] = useState<Absence[]>([]);
  const [isLoadingAbsences, setIsLoadingAbsences] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const getQuotaData = async () => {
      setIsLoadingQuotas(true);
      //for quotas we only fetch for current year always.
      try {
        const quotaData = await getDataByEmployee(
          instance,
          'quotas',
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31),
          abortController,
        );
        setQuotaData(quotaData);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      } finally {
        setIsLoadingQuotas(false);
      }
    };
    getQuotaData();
    return () => {
      abortController.abort();
    };
  }, [instance]);

  useEffect(() => {
    const abortController = new AbortController();

    const getAbsenceData = async () => {
      setIsLoadingAbsences(true);
      try {
        const absenceData = await getDataByEmployee(instance, 'absenceAttendance', new Date(year, 0, 1), new Date(year, 11, 31), abortController);
        setAbsenceData(absenceData);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      } finally {
        setIsLoadingAbsences(false);
      }
    };

    getAbsenceData();
    return () => {
      abortController.abort();
    };
  }, [instance, year]);

  return (
    <HorizontalStack align={'flex-start'} spacing={5} divider={<Stack.Divider orientation='vertical' />} m={4}>
      <Box w={{ base: '45%', md: '35%' }}>
        <EmployeeQuotaViewer quotas={quotaData} isLoading={isLoadingQuotas} />
      </Box>
      <Box w={'100%'}>
        <PeriodeCarousel periodes={periodes} initialSelection={periodes.length - 1} onSelectionChange={(p) => setYear(Number(p.id))} />
        <TableViewer data={absenceData} columns={columns} isLoading={isLoadingAbsences}></TableViewer>
        {/* <EmployeeAbsenceViewer absences={absenceData} isLoading={isLoadingAbsences} /> */}
      </Box>
    </HorizontalStack>
  );
};
