import { useMsal } from '@azure/msal-react';
import { Absence, Quota, TimeEvent } from '@ta-global-kiosk/domain';
import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { getDataByEmployee } from '../utils/data/time';

export type TEmployeeDataContext = {
  fetching: boolean;
  quotaData: Quota[];
  absenceData: Absence[];
  timeEventData: TimeEvent[];
  reloadData: () => void;
};

const EmployeeDataContext = createContext<any>({});

export const EmployeeDataProvider = ({ children }: any) => {
  const [fetching, setFetching] = useState(true);

  const [quotaData, setQuotaData] = useState<Quota[]>([]);
  const [absenceData, setAbsenceData] = useState<Absence[]>([]);

  const [timeEventData, setTimeEventData] = useState<TimeEvent[]>([]);

  const { instance } = useMsal();

  const reloadData = useCallback(() => {
    const abortController = new AbortController();
    setFetching(true);
    const year = new Date().getFullYear();
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    const fetchData = async () => {
      const absenceAttendanceData = await getDataByEmployee(
        instance,
        'absenceAttendance',
        start,
        end,
        abortController,
      );
      const quotaData = await getDataByEmployee(instance, 'quotas', start, end, abortController);
      setQuotaData(quotaData);
      setAbsenceData(absenceAttendanceData);
      setTimeEventData([]);
      setFetching(false);
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, [instance]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  return (
    <EmployeeDataContext.Provider
      value={{ fetching, reloadData, quotaData, absenceData, timeEventData }}>
      {children}
    </EmployeeDataContext.Provider>
  );
};

export const useEmployeeData = () => useContext<TEmployeeDataContext>(EmployeeDataContext);
