import { Box, Button, Container, LEGOLogo, Text } from '@lego/klik-ui';
import { StatusError } from '@lego/klik-ui-icons';
import { Link } from 'react-router-dom';

export const ErrorPage = () => {
  return (
    <Container maxWidth='md' mt={10} textAlign='center'>
      <LEGOLogo boxSize='120px' />
      <Box mt={5}>
        <StatusError fontSize={50} />
        <Text mb={2}>
          Ups... Something went wrong. The page you are trying to reach did not exist our you do not
          have access!
        </Text>
        <Button mt={5} as={Link} to='/'>
          Take me home
        </Button>
      </Box>
    </Container>
  );
};
