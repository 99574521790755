import { HorizontalStack, IconButton, Text } from '@lego/klik-ui';
import { ArrowLeftBold, ArrowRightBold } from '@lego/klik-ui/icons';
import { useMemo, useState } from 'react';

export interface Periode {
  id: string;
  display: string;
  start: string;
  end: string;
}
export interface PeriodeCarouselProps {
  periodes: Periode[];
  initialSelection: number;
  onSelectionChange?: (newSelection: Periode) => void;
}

export const PeriodeCarousel = (props: PeriodeCarouselProps) => {
  const [selectedIndex, setSelectedIndex] = useState(props.initialSelection);
  const selectedPeriode = useMemo(() => props.periodes[selectedIndex], [props.periodes, selectedIndex]);

  const canGoForward = useMemo(() => selectedIndex < props.periodes.length - 1, [props.periodes, selectedIndex]);
  const canGoBackward = useMemo(() => selectedIndex > 0, [selectedIndex]);

  const onNavigation = (direction: number) => {
    const newIndex = selectedIndex + direction;
    const newSelection = props.periodes[newIndex];
    setSelectedIndex(newIndex);
    if (props.onSelectionChange) props.onSelectionChange(newSelection);
  };
  return (
    <HorizontalStack placeContent='center'>
      {canGoBackward && (
        <IconButton
          name='carousel-back'
          size='sm'
          variant='ghost'
          onClick={() => onNavigation(-1)}
          icon={<ArrowLeftBold />}
          aria-label='left'></IconButton>
      )}
      <Text fontWeight='bold'>{selectedPeriode.display}</Text>
      {canGoForward && (
        <IconButton
          name='carousel-forward'
          size='sm'
          variant='ghost'
          onClick={() => onNavigation(1)}
          icon={<ArrowRightBold />}
          aria-label='right'></IconButton>
      )}
    </HorizontalStack>
  );
};
