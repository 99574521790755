import { Box, CircularProgress, Container, Stack, Text } from '@lego/klik-ui';
import { ResultType, useGet } from '../utils/data/useGet';
import { useMemo } from 'react';
import { StatusError } from '@lego/klik-ui/icons';

export interface PdfViewerProps {
  url: string | undefined;
  authScopes: string[];
}

export const PdfViewer = ({ url, authScopes }: PdfViewerProps) => {
  const { isLoading, data, error } = useGet({ url, authScopes, resultType: ResultType.BLOB });

  const embedSrc = useMemo(() => {
    const dataBlob = data as Blob;
    if (dataBlob !== null) {
      if (dataBlob.size > 0) return window.URL.createObjectURL(dataBlob);
    }
    return undefined;
  }, [data]);
  return (
    <Stack alignItems='center'>
      {isLoading && !error && <CircularProgress mt={10} isIndeterminate={true} value={3} />}
      {error && (
        <Container maxWidth='md' mt={10} textAlign='center'>
          <Box mt={5}>
            <StatusError fontSize={50} />
            <Text mb={2}>Something went wrong while getting the data.</Text>
          </Box>
        </Container>
      )}
      {!isLoading && !error && !embedSrc && (
        <Container maxWidth='md' mt={10} textAlign='center'>
          <Box mt={5}>
            <StatusError fontSize={50} />
            <Text mb={2}>The file is not available.</Text>
          </Box>
        </Container>
      )}
      {!isLoading && embedSrc && (
        <embed src={embedSrc + '#toolbar=1&statusbar=1'} title='TimeSheet' height={window.innerHeight - 100 + 'px'} width='100%' id='pdfViewer' />
      )}
    </Stack>
  );
};
