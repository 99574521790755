import apm from '../utils/monitoring/apm';
import { EmployeeData } from '@ta-global-kiosk/domain';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getEmployeeData } from '../utils/data/kiosk';
import { useAuthData } from './AuthProvider';
import { APP_SCOPE } from '../utils/config/ConfigConstants';
import { useToast } from '@lego/klik-ui';
import { createNotification } from '../utils/notification';

export type TKioskDataContext = {
  fetching: boolean;
  isLoggedIn: boolean;
  login: (badgeId: string) => void;
  logout: () => void;
  employeeData: EmployeeData | undefined;
  reloadData: () => void;
};

const EVENTS = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const LOGOUT_TIMEOUT = 30000;

const KioskDataContext = createContext<any>({});

export const KioskDataProvider = ({ children }: any) => {
  const { user, instance } = useAuthData();
  const toast = useToast();
  const [badgeId, setBadgeId] = useState<string | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [employeeData, setEmployeeData] = useState<EmployeeData | undefined>();

  const logoutTimer = useRef<NodeJS.Timeout | undefined>();

  const login = async (badgeId: string) => {
    apm.getCurrentTransaction()?.addLabels({ badgeId });
    setFetching(true);
    setBadgeId(badgeId);
    setIsLoggedIn(true);
  };

  const logout = useCallback(() => {
    setBadgeId(undefined);
    setIsLoggedIn(false);
    toast(
      createNotification({
        title: 'Logged out',
        description: 'You have been logged out',
        variant: 'info',
      }),
    );
  }, [toast]);

  const loadData = useCallback(async () => {
    if (user?.isKioskServiceAccount && !isLoggedIn) {
      return;
    }
    const { accessToken } = await instance.acquireTokenSilent({
      scopes: [APP_SCOPE],
    });
    try {
      const employeeData = await getEmployeeData(accessToken, badgeId);
      if (!employeeData || !employeeData.employeeNumber) {
        toast(
          createNotification({
            title: 'Error',
            description: 'Something went wrong during the login process.',
            variant: 'error',
          }),
        );
        setBadgeId(undefined);
        setIsLoggedIn(false);
        return;
      }
      setEmployeeData({ badgeId, ...employeeData });

      apm.addLabels({
        isKioskServiceAccount: user?.isKioskServiceAccount + '',
        isManager: user?.isManager + '',
        employeeNumber: employeeData.employeeNumber,
        badgeId: employeeData.badgeId + '',
      });
    } catch (error) {
      apm.captureError(error + '');

      toast(
        createNotification({
          title: 'Error',
          description: 'Something went wrong during the login process.',
          variant: 'error',
        }),
      );

      setBadgeId(undefined);
      setIsLoggedIn(false);
    }
    setFetching(false);
  }, [instance, badgeId, user?.isKioskServiceAccount, user?.isManager, isLoggedIn, toast]);

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = useCallback(() => {
    if (logoutTimer.current) clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(logout, LOGOUT_TIMEOUT);
  }, [logout]);

  useEffect(() => {
    loadData();
  }, [loadData, badgeId]);

  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    if (isLoggedIn) {
      Object.values(EVENTS).forEach((item) => {
        window.addEventListener(item, handleLogoutTimer);
      });
    }
    return () => {
      Object.values(EVENTS).forEach((item) => {
        window.removeEventListener(item, handleLogoutTimer);
      });
    };
  }, [handleLogoutTimer, isLoggedIn]);

  const val: TKioskDataContext = {
    fetching,
    isLoggedIn,
    login,
    logout,
    employeeData,
    reloadData: loadData,
  };
  return <KioskDataContext.Provider value={val}>{children}</KioskDataContext.Provider>;
};

export const useKioskData = () => useContext<TKioskDataContext>(KioskDataContext);
