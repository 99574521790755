import { Box, Stack } from '@lego/klik-ui';
import { useEffect, useMemo, useState } from 'react';
import { PdfViewer } from '../../components/PdfViewer';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';
import { useKioskData } from '../../contexts/KioskDataProvider';
import { APP_SCOPE, BASE_API_URL } from '../../utils/config/ConfigConstants';
import { generateSemiMonthPeriods, generateWeekPeriods, parseDate } from '../../utils/datetime';

const generatePayslipUrl = (periode: Periode | null, employeeNumber: string) => {
  if (periode) {
    const { start, end } = periode;
    return `${BASE_API_URL}/payslips?employeeNumber=${employeeNumber}&startDate=${parseDate(start)!.toISOString()}&endDate=${parseDate(
      end,
    )!.toISOString()}`;
  }
  return undefined;
};

export const Payslips = () => {
  const { employeeData } = useKioskData();

  const periodes: Periode[] = useMemo(() => {
    const isSemimonthly = employeeData?.company.indexOf('1056') === 0 ? true : false;
    const periodes = isSemimonthly ? generateSemiMonthPeriods(12, true) : generateWeekPeriods(26, true);
    periodes.reverse();
    const today = new Date();
    if (!isSemimonthly && today.getDay() < 4) {
      periodes.pop();
    }
    if ((isSemimonthly && today.getDate() > 0 && today.getDate() < 4) || (today.getDate() > 15 && today.getDate() < 19)) {
      periodes.pop();
    }
    return periodes;
  }, [employeeData?.company]);
  const [selectedPeriode, setSelectedPeriode] = useState<Periode>(periodes[periodes.length - 1]);
  const [pdfUrl, setPdfUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!employeeData) return;
    setPdfUrl(generatePayslipUrl(selectedPeriode, employeeData?.extPayrollId || employeeData?.employeeNumber!));
  }, [selectedPeriode, employeeData]);

  return (
    <Stack spacing={0}>
      <Box bg='slate.100' p={2}>
        <PeriodeCarousel
          onSelectionChange={(periode) => {
            setSelectedPeriode(periode);
          }}
          initialSelection={periodes.length - 1}
          periodes={periodes}
        />
      </Box>

      <PdfViewer authScopes={[APP_SCOPE]} url={pdfUrl} />
    </Stack>
  );
};
