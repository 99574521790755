import { Grid, Text, Card, Box, SkeletonText, Table } from '@lego/klik-ui';
import { useTranslation } from 'react-i18next';
import { useAuthData } from '../../contexts/AuthProvider';
import { useEmployeeData } from '../../contexts/EmployeeDataProvider';
import { parseDate } from '../../utils/datetime';

export const Home = () => {
  const { user } = useAuthData();
  const { fetching, quotaData } = useEmployeeData();
  const { t } = useTranslation();

  return (
    <Grid
      mt={10}
      gap={4}
      h='800px'
      w='100%'
      templateColumns='repeat(6, 1fr)'
      templateRows='repeat(3, 1fr)'>
      <Grid.Item colSpan={3} rowSpan={3}>
        <Text as='h2' textStyle='h2'>
          Welcome {user?.displayName}
        </Text>
      </Grid.Item>
      <Grid.Item colSpan={3} rowSpan={3}>
        {/* Here comes action cards */}
        <Card width='100%' minHeight={'30%'}>
          <Box m='3'>
            {/* <Text as='h4' textStyle='h4' mb={2}>
              Quotas
            </Text> */}
            {fetching && <SkeletonText mt={5} noOfLines={6} skeletonHeight='2rem' spacing='3' />}
            {!fetching && (
              <Table size={'sm'}>
                <Table.Head>
                  <Table.Row>
                    <Table.ColumnHeader backgroundColor='transparent'>
                      {t('Quota.name')}
                    </Table.ColumnHeader>
                    <Table.ColumnHeader backgroundColor='transparent'>
                      {t('Quota.valid-to')}
                    </Table.ColumnHeader>
                    <Table.ColumnHeader backgroundColor='transparent'>
                      {t('Quota.remaining')}
                    </Table.ColumnHeader>
                    <Table.ColumnHeader backgroundColor='transparent'>
                      {t('Quota.used')}
                    </Table.ColumnHeader>
                  </Table.Row>
                </Table.Head>
                <Table.Body fontSize={'sm'}>
                  {quotaData
                    .sort((a, b) => a.endDate.localeCompare(b.endDate))
                    .map((quota) => {
                      return (
                        <Table.Row>
                          <Table.Cell>{quota.name}</Table.Cell>
                          <Table.Cell>{parseDate(quota.endDate)?.toLocaleDateString()}</Table.Cell>
                          <Table.Cell>{Number(quota.daysRemaining).toFixed(2)}</Table.Cell>
                          <Table.Cell>{Number(quota.daysUsed).toFixed(2)}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            )}
          </Box>
        </Card>
      </Grid.Item>
    </Grid>
  );
};
