import { useEffect, useMemo, useState } from 'react';
import { generateWeekPeriods } from '../../utils/datetime';
import { useAuthData } from '../../contexts/AuthProvider';
import { TimeEvent } from '@ta-global-kiosk/domain';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { TableViewer } from '../../components/TableViewer';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';
import { Box } from '@lego/klik-ui';
import { getDataByEmployee } from '../../utils/data/time';

export const TimeEvents = () => {
  const periodes = useMemo(() => {
    return generateWeekPeriods(60).reverse();
  }, []);
  const [periode, setPeriode] = useState<Periode>(periodes[periodes.length - 1]);
  const { instance } = useAuthData();
  const [data, setData] = useState<TimeEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return getColumns(t);
  }, [t]);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      setIsLoading(true);
      try {
        const [start, end] = periode.id.split('#');
        const data = await getDataByEmployee(instance, 'timeEvents', new Date(start), new Date(end), abortController);
        setData(data);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    return () => {
      abortController.abort();
    };
  }, [instance, periode]);

  return (
    <Box w={'100%'}>
      <PeriodeCarousel periodes={periodes} initialSelection={periodes.length - 1} onSelectionChange={(p) => setPeriode(p)} />
      <TableViewer data={data} columns={columns} isLoading={isLoading}></TableViewer>
    </Box>
  );
};

const getColumns = (t: any) => {
  const columnHelper = createColumnHelper<TimeEvent>();
  return [
    // columnHelper.accessor('sapId', {
    //   header: () => t('TimeEvent.sap_id'),
    //   maxSize: 130,
    //   cell: (info) => info.getValue().replace(/^0+/, ''),
    // }),
    columnHelper.accessor('type', {
      header: () => t('TimeEvent.type'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('reason', {
      header: () => t('TimeEvent.reason'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('logicalDate', {
      header: () => t('TimeEvent.date'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('logicalTime', {
      header: () => t('TimeEvent.time'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('terminalId', {
      header: () => t('TimeEvent.terminalId'),
      maxSize: 130,
      cell: (info) => info.getValue(),
    }),
  ];
};
