import { Box, Stack } from '@lego/klik-ui';
import { useEffect, useMemo, useState } from 'react';
import { PdfViewer } from '../../components/PdfViewer';
import { Periode, PeriodeCarousel } from '../../components/PeriodeCarousel';

import { useKioskData } from '../../contexts/KioskDataProvider';
import { APP_SCOPE, BASE_API_URL } from '../../utils/config/ConfigConstants';

import { generateWeekPeriods } from '../../utils/datetime';

const generateTimesheetUrl = (employeeNumber: string, periode: Periode) => {
  const { start, end } = periode;
  return `${BASE_API_URL}/timesheets?employeeNumber=${employeeNumber}&startDate=${start}&endDate=${end}`;
};

export const TimeSheets = () => {
  const periodes = useMemo(() => {
    return generateWeekPeriods(52).reverse();
  }, []);
  const [periode, setPeriode] = useState(periodes[periodes.length - 2]);
  const { employeeData } = useKioskData();
  const [pdfUrl, setPdfUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!employeeData) return;
    setPdfUrl(generateTimesheetUrl(employeeData?.employeeNumber, periode));
  }, [periode, employeeData]);

  return (
    <Stack spacing={0}>
      <Box bg='slate.100' p={2}>
        <PeriodeCarousel periodes={periodes} initialSelection={periodes.length - 2} onSelectionChange={(p) => setPeriode(p)} />
      </Box>

      <PdfViewer authScopes={[APP_SCOPE]} url={pdfUrl} />
    </Stack>
  );
};
