import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { DKManagerRoutes, CNManagerRoutes, HourlyRoutes, MXRoutes, NO_MATCH_ROUTE } from './Constants';

//Generic Imports
import { OutletLayout } from '../OutletLayout';
import { ErrorPage } from './ErrorPage';

import { useKioskData } from '../../contexts/KioskDataProvider';
import { KioskRouteProps, Location } from '../../utils/types';
import { useAuthData } from '../../contexts/AuthProvider';
import { Login } from '../kiosk/Login';

export const Router = () => {
  const { user } = useAuthData();
  const { isLoggedIn, fetching } = useKioskData();
  let routes: KioskRouteProps[] = [];
  switch (user?.location) {
    case Location.DK:
      routes = user?.isManager ? DKManagerRoutes : HourlyRoutes;
      break;
    case Location.CN:
      routes = user?.isManager ? CNManagerRoutes : HourlyRoutes;
      break;
    case Location.HU:
    case Location.CZ:
      routes = HourlyRoutes;
      break;
    case Location.MX:
      routes =
        user?.isKioskServiceAccount && (!isLoggedIn || (isLoggedIn && fetching))
          ? [{ title: 'Navigation.badge_login', path: '/', element: <Login /> }]
          : MXRoutes;
      break;
    default:
      routes = [];
      break;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route key={'base'} element={<OutletLayout navItems={routes} />}>
          {routes.map((r) => {
            return (
              <>
                {r.dropdownItems &&
                  r.dropdownItems.map((d) => {
                    return <Route key={d.title} {...d}></Route>;
                  })}
                <Route key={r.title} {...r}></Route>
              </>
            );
          })}
        </Route>
        <Route key={'*'} path={NO_MATCH_ROUTE} element={<ErrorPage />} />
      </Routes>
      {user && user.isKioskServiceAccount && window.location.pathname !== '/' && (!isLoggedIn || (isLoggedIn && fetching)) && <Navigate to={'/'} />}
    </BrowserRouter>
  );
};
